import * as _os2 from "os";

var _os = "default" in _os2 ? _os2.default : _os2;

import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

import * as _yaml2 from "yaml";

var _yaml = "default" in _yaml2 ? _yaml2.default : _yaml2;

import * as _fs2 from "fs";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

import * as _lodash2 from "lodash.clonedeep";

var _lodash = "default" in _lodash2 ? _lodash2.default : _lodash2;

var exports = {};
const {
  homedir
} = _os;
const {
  join
} = _path;
const {
  parse
} = _yaml;
const {
  readFileSync
} = _fs;
const cloneDeep = _lodash;

function defaultConfigPath() {
  return join(homedir(), ".ngrok2", "ngrok.yml");
}

function defaults(opts) {
  opts = cloneDeep(opts) || {
    proto: "http",
    addr: 80
  };

  if (opts.name) {
    const configPath = opts.configPath || defaultConfigPath();
    const config = parse(readFileSync(configPath, "utf8"));

    if (config.tunnels && config.tunnels[opts.name]) {
      opts = Object.assign(opts, config.tunnels[opts.name]);
    }
  }

  if (typeof opts === "function") opts = {
    proto: "http",
    addr: 80
  };
  if (typeof opts !== "object") opts = {
    proto: "http",
    addr: opts
  };
  if (!opts.proto) opts.proto = "http";
  if (!opts.addr) opts.addr = opts.port || opts.host || 80;
  if (opts.httpauth) opts.auth = opts.httpauth;
  return opts;
}

function validate(opts) {
  if (opts.web_addr === false || opts.web_addr === "false") {
    throw new Error("web_addr:false is not supported, module depends on internal ngrok api");
  }
}

function isRetriable(err) {
  if (!err.response) {
    return false;
  }

  const statusCode = err.response.statusCode;
  const body = err.body;
  const notReady500 = statusCode === 500 && /panic/.test(body);
  const notReady502 = statusCode === 502 && body.details && body.details.err === "tunnel session not ready yet";
  const notReady503 = statusCode === 503 && body.details && body.details.err === "a successful ngrok tunnel session has not yet been established";
  return notReady500 || notReady502 || notReady503;
}

exports = {
  defaults,
  validate,
  isRetriable
};
export default exports;