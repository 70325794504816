import _client from "./src/client";
import * as _uuid2 from "uuid";

var _uuid = "default" in _uuid2 ? _uuid2.default : _uuid2;

import _process from "./src/process";
import _utils from "./src/utils";
var exports = {};
const {
  NgrokClient,
  NgrokClientError
} = _client;
const uuid = _uuid;
const {
  getProcess,
  killProcess,
  setAuthtoken,
  getVersion
} = _process;
const {
  defaults,
  validate,
  isRetriable
} = _utils;
let processUrl = null;
let ngrokClient = null;

async function connect(opts) {
  opts = defaults(opts);
  validate(opts);

  if (opts.authtoken) {
    await setAuthtoken(opts);
  }

  processUrl = await getProcess(opts);
  ngrokClient = new NgrokClient(processUrl);
  return connectRetry(opts);
}

async function connectRetry(opts, retryCount = 0) {
  opts.name = String(opts.name || uuid.v4());

  try {
    const response = await ngrokClient.startTunnel(opts);
    return response.public_url;
  } catch (err) {
    if (!isRetriable(err) || retryCount >= 100) {
      throw err;
    }

    await new Promise(resolve => setTimeout(resolve, 200));
    return connectRetry(opts, ++retryCount);
  }
}

async function disconnect(publicUrl) {
  if (!ngrokClient) return;
  const tunnels = (await ngrokClient.listTunnels()).tunnels;

  if (!publicUrl) {
    const disconnectAll = tunnels.map(tunnel => disconnect(tunnel.public_url));
    return Promise.all(disconnectAll);
  }

  const tunnelDetails = tunnels.find(tunnel => tunnel.public_url === publicUrl);

  if (!tunnelDetails) {
    throw new Error(`there is no tunnel with url: ${publicUrl}`);
  }

  return ngrokClient.stopTunnel(tunnelDetails.name);
}

async function kill() {
  if (!ngrokClient) return;
  await killProcess();
  ngrokClient = null;
}

function getUrl() {
  return processUrl;
}

function getApi() {
  return ngrokClient;
}

exports = {
  connect,
  disconnect,
  authtoken: setAuthtoken,
  kill,
  getUrl,
  getApi,
  getVersion,
  NgrokClient,
  NgrokClientError
};
export default exports;
export const authtoken = exports.authtoken;
const _connect = exports.connect,
      _disconnect = exports.disconnect,
      _kill = exports.kill,
      _getUrl = exports.getUrl,
      _getApi = exports.getApi,
      _getVersion = exports.getVersion,
      _NgrokClient = exports.NgrokClient,
      _NgrokClientError = exports.NgrokClientError;
export { _connect as connect, _disconnect as disconnect, _kill as kill, _getUrl as getUrl, _getApi as getApi, _getVersion as getVersion, _NgrokClient as NgrokClient, _NgrokClientError as NgrokClientError };